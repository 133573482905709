
import { macroCondition, getGlobalConfig } from '@embroider/macros';


import implicitModules from "./-embroider-implicit-modules.js";


import * as amdModule0 from "./app.ts"
import * as amdModule1 from "./config/environment.ts"
import * as amdModule2 from "./registry.ts"
import * as amdModule3 from "./router.ts"
import * as amdModule4 from "./services/docs.ts"
import * as amdModule5 from "./services/selected.ts"
import * as amdModule6 from "./utils.ts"
import * as amdModule7 from "./services/-ensure-registered.js"
import * as amdModule8 from "./services/page-title.js"
import * as amdModule9 from "./services/kolay/api-docs.js"
import * as amdModule10 from "./services/kolay/compiler.js"
import * as amdModule11 from "./services/kolay/docs.js"
import * as amdModule12 from "./services/kolay/selected.js"
import * as amdModule13 from "./templates/application.gts"
import * as amdModule14 from "./controllers/application.ts"
import * as amdModule15 from "./routes/application.ts"
import * as amdModule16 from "./routes/index.ts"

let exportFastbootModules = {};





export default Object.assign(
  {},
  implicitModules,
  {
"tutorial/app": amdModule0,
"tutorial/config/environment": amdModule1,
"tutorial/registry": amdModule2,
"tutorial/router": amdModule3,
"tutorial/services/docs": amdModule4,
"tutorial/services/selected": amdModule5,
"tutorial/utils": amdModule6,
"tutorial/services/-ensure-registered": amdModule7,
"tutorial/services/page-title": amdModule8,
"tutorial/services/kolay/api-docs": amdModule9,
"tutorial/services/kolay/compiler": amdModule10,
"tutorial/services/kolay/docs": amdModule11,
"tutorial/services/kolay/selected": amdModule12,
"tutorial/templates/application": amdModule13,
"tutorial/controllers/application": amdModule14,
"tutorial/routes/application": amdModule15,
"tutorial/routes/index": amdModule16,
  },
  exportFastbootModules
);
